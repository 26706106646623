
import { defineComponent, onMounted } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment";

export default defineComponent({
  name: "widget-12",
  props: {
    widgetClasses: String,
    widgetColor: String,
    chartHeight: String,
    chartTitle: String,
    tes: Array,
    colorStick: String,
    jumlah: String,
  },
  components: {
    Dropdown3,
  },
  setup(props) {
    var dataValue = [];
    // var cek = JSON.parse(props.tes)
    const series = props.tes;

    const tahun = moment().format("YYYY");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const chartTitle = props.chartTitle;
    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          borderRadius: 5,
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 16,
        colors: ["transparent"],
      },
      xaxis: {
        // tickAmount,
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Okt",
          "Nov",
          "Des",
        ],

        labels: {
          // show: true,
          style: {
            colors: ["#000"],
            fontSize: "10px",
          },
        },
      },
      yaxis: {
        min: 0,
        max: 80,
        labels: {
          style: {
            colors: ["#000"],
            fontSize: "10px",
          },
        },
      },
      fill: {
        type: ["solid", "solid"],
        opacity: [0.9, 1],
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "" + val + " ";
          },
        },
        marker: {
          show: false,
        },
      },
      colors: [props.colorStick, "rgba(196, 196, 196, 0.8)"],
      grid: {
        borderColor: "#c4c4c4",
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          left: 20,
          right: 20,
        },
      },
    };

    onMounted(() => {
      // getDataMonitor()
    });

    return {
      // getDataMonitor,
      tahun,
      series,
      chartOptions,
      chartTitle,
    };
  },
});
