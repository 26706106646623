
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import Chart from "@/components/1crm/dashboard/superadmin/Widget11.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import store from "@/store";
import moment from "moment";

export default defineComponent({
  name: "management-proses",

  data() {
    return {
      childData: false,
      tahun: moment().format("YYYY"),
      colorWebinar: "#FFB700",
      colorPresentation: "#012D95",
      colorProposal: "#62BC96",
      colorFollowUp: "#E50200",
      colorContract: "#FFA800",
      colorPreImplementasi: "#8950FC",
      colorCollect: "#ff4",
      colorBilling: "#E50200",
      colorAfterSales: "#1BC4BD",
      colorImplementasi: "#3268FF",
      colorTotal: "#012D95",
      dataWebinar: [
        {
          name: "",
          data: [],
        },
      ],
      dataPresentation: [
        {
          name: "",
          data: [],
        },
      ],
      dataCollect: [
        {
          name: "",
          data: [],
        },
      ],
      dataProposal: [
        {
          name: "",
          data: [],
        },
      ],
      dataFollowUp: [
        {
          name: "",
          data: [],
        },
      ],
      dataContract: [
        {
          name: "",
          data: [],
        },
      ],
      dataPreImplementasi: [
        {
          name: "",
          data: [],
        },
      ],
      dataBilling: [
        {
          name: "",
          data: [],
        },
      ],
      dataImplementasi: [
        {
          name: "",
          data: [],
        },
      ],
      dataAfterSales: [
        {
          name: "",
          data: [],
        },
      ],
      dataTotal: [
        {
          name: "",
          data: [],
        },
      ],
      totalWebinar: 0,
      totalPresentation: 0,
      totalProposal: 0,
      totalFollowUp: 0,
      totalContract: 0,
      totalPreImplementasi: 0,
      totalBilling: 0,
      totalDataCollect: 0,
      totalImplementasi: 0,
      totalAfterSales: 0,
      totalTotal: 0,
    };
  },
  components: {
    ErrorMessage,
    Field,
    Form,
    Chart,

    // AddSchool,
    // GoogleMap
  },
  mounted() {
    store.dispatch(Actions.VERIFY_AUTH);

    setCurrentPageBreadcrumbs("Management Proses", ["Sekolah"]);

    this.getDataMonitor();
    // this.roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
    // this.getData(this.paging.size, this.paging.page, this.filterConfir);
    // this.getListSekolah();
    // setCurrentPageBreadcrumbs("Data NPWP", ["Data"]);
  },
  computed: {
    to() {},
    from() {},
  },
  methods: {
    getDataMonitor() {
      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/monitoring/process/" + this.tahun
      )
        .then((resp) => {
          (this.totalWebinar = resp.data.sumWebinar),
            (this.totalPresentation = resp.data.sumPresentation),
            (this.totalProposal = resp.data.sumProposal),
            (this.totalFollowUp = resp.data.sumFollowUp),
            (this.totalContract = resp.data.sumContract),
            (this.totalPreImplementasi = resp.data.sumPreImplementasi),
            (this.totalBilling = resp.data.sumBilling),
            (this.totalImplementasi = resp.data.sumImplementasi),
            (this.totalDataCollect = resp.data.sumDataCollect),
            (this.totalAfterSales = resp.data.sumAfterSales),
            (this.totalTotal = resp.data.sumTotal),
            (this.dataWebinar = [
              {
                name: "Webinar",
                data: resp.data.webinar,
              },
            ]),
            (this.dataPresentation = [
              {
                name: "Presentasi",
                data: resp.data.presentation,
              },
            ]),
            (this.dataProposal = [
              {
                name: "Proposal",
                data: resp.data.proposal,
              },
            ]),
            (this.dataCollect = [
              {
                name: "Pengumpulan Data",
                data: resp.data.dataCollect,
              },
            ]),
            (this.dataFollowUp = [
              {
                name: "Follow Up",
                data: resp.data.followUp,
              },
            ]),
            (this.dataContract = [
              {
                name: "PKS",
                data: resp.data.contract,
              },
            ]),
            (this.dataPreImplementasi = [
              {
                name: "Pre Implementasi",
                data: resp.data.preImplementasi,
              },
            ]),
            (this.dataBilling = [
              {
                name: "Tagihan",
                data: resp.data.billing,
              },
            ]),
            (this.dataImplementasi = [
              {
                name: "Implementasi",
                data: resp.data.implementasi,
              },
            ]),
            (this.dataAfterSales = [
              {
                name: "After Sales",
                data: resp.data.afterSales,
              },
            ]),
            (this.dataTotal = [
              {
                name: "Total",
                data: resp.data.total,
              },
            ]),
            this.getDatastatus();

          // console.log(this.childData, "hjd ")

          // return this.dataWebinar
          // resp.data.Webinar
        })
        .catch((e) => {});
    },
    getDatastatus() {
      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/monitoring/progress/" +
          this.tahun +
          "/1"
      )
        .then((resp) => {
          console.log("ress = ", resp.data);
          this.dataWebinar.push({
            name: "Proses Webinar",
            data: resp.data.webinar,
          }),
            this.dataPresentation.push({
              name: "Proses Presentasi",
              data: resp.data.presentation,
            }),
            this.dataCollect.push({
              name: "Proses Pengumpulan Data",
              data: resp.data.dataCollect,
            }),
            this.dataProposal.push({
              name: "Proses Proposal",
              data: resp.data.proposal,
            }),
            this.dataFollowUp.push({
              name: "Proses Follow Up",
              data: resp.data.followUp,
            }),
            this.dataContract.push({
              name: "Proses PKS",
              data: resp.data.contract,
            }),
            this.dataPreImplementasi.push({
              name: "Proses Pre Implementasi",
              data: resp.data.preImplementasi,
            }),
            this.dataBilling.push({
              name: "Proses Tagihan",
              data: resp.data.billing,
            }),
            this.dataImplementasi.push({
              name: "Proses Implementasi",
              data: resp.data.implementasi,
            }),
            this.dataAfterSales.push({
              name: "Proses After Sales",
              data: resp.data.afterSales,
            }),
            this.dataTotal.push({
              name: "Proses Total",
              data: resp.data.total,
            }),
            (this.childData = true);

          // return this.dataLeads
          // resp.data.leads
        })
        .catch((e) => {
          console.log("ress = ", e);
        });
    },
  },
});
